export enum taxRates {
  KEIGEN_RATE = 8.0,
}

export enum OrderStatus {
  STATUS_DRAFT = 0,
  STATUS_ORDERED = 1,
  STATUS_PAID = 2,
  STATUS_CONFIRM = 3,
  STATUS_CANCELED = -1,
}

export enum OrderCode {
  CODE_BY_HALLSTAFF = 'hall',
  CODE_BY_TAKEOUT = 'takeout',
}

export enum ServedCode {
  SERVED_CANCEL = 0,
  SERVED_ORDER = 1,
  SERVED_READY = 2,
  SERVED_DONE = 3,
}
