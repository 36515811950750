import { AxiosPromise } from 'axios';
import API from './api';
import * as checkin from '../../model/checkin';
import * as order from '../../model/order';
import * as holiday from '../../model/holiday';

class FrontAPI extends API {
    /**
     * チェックイン
     */
    public checkin(args: checkin.request, query: checkin.requestQuery): AxiosPromise<checkin.response> {
        return super.request('/checkin.json', 'POST', args, query);
    }

    /**
     * オーダー実行
     */
    public order(data: order.Request, query: order.RequestQuery): AxiosPromise<order.Respons> {
        return super.request('/orders.json', 'POST', data, query);
    }

    /**
     * 祝日取得
     */
    public holiday(query: holiday.RequestQuery): AxiosPromise<holiday.Response> {
        return super.request('/holidays.json', 'GET', null, query);
    }
}

export default FrontAPI;
