import axios, { Method, AxiosPromise } from 'axios';
import _ from 'lodash';

import * as translate from '../../model/translate';
import * as iot from '../../model/iot';
import * as menu from '../../model/menu';
import * as menuHall from '../../model/hallMenu';
import * as hallCart from '../../model/hallCart';
import * as hallHistory from '../../model/hallHistory';
import * as hallCheck from '../../model/hallCheck';
import * as orderHistory from '../../model/orderHistory';
import * as tableInfo from '../../model/tableInfo';
import {MenuDetail} from "@/model/menu";

/**
 * API 基底クラス
 * */
class API {
  protected API_BASE_URL: string;
  protected headers: {[key: string]: string} = {};

  constructor() {
    // setup base url
    this.API_BASE_URL = process.env.VUE_APP_HOST_API + '/v1';

    this.headers = {
        'Content-Type': 'application/json'
    };
  }

  public request<T>(path: string, method: Method, data: any, query: any): AxiosPromise<T> {
    return axios({
      url: this.API_BASE_URL + path,
      params: query,
      headers: this.headers,
      method,
      data,
    });
  }


  /**
   * 静的翻訳情報取得
   */
  public translate(args: translate.RequestQuery): AxiosPromise<translate.Response> {
    return this.request('/languages/static.json', 'GET', null, args);
  }

  /**
   * メニュー情報取得
   */
  public menu(query: menu.RequestQuery): AxiosPromise<menu.Response> {
      return this.request('/menus.json', 'GET', null, query);
  }

  public menuDetail(query: menu.MenuDetail): AxiosPromise<menu.Response> {
    return this.request('/menus/detail.json', 'GET', null, query);
  }

  /**
   * 注文一覧取得
   */
  public hallMenu(query: menuHall.RequestQuery): AxiosPromise<menuHall.Response> {
      return this.request('/hall-menus.json', 'GET', null, query);
  }

  /**
   * 注文確認一覧取得
   */
  public hallCart(query: hallCart.RequestQuery): AxiosPromise<hallCart.Response> {
      return this.request('/hall-menus/cart.json', 'GET', null, query);
  }

  /**
   * 注文履歴一覧取得
   */
  public hallHistory(query: hallHistory.RequestQuery): AxiosPromise<hallHistory.Response> {
      return this.request('/hall-menus/history.json', 'GET', null, query);
  }

  /**
   * 会計
   */
  public hallCheck(query: hallCheck.RequestQuery): AxiosPromise<hallCheck.Response> {
      return this.request('/hall-menus/check.json', 'GET', null, query);
  }

  /**
   * 注文履歴
   */
  public orderHistory(query: orderHistory.RequestQuery): AxiosPromise<orderHistory.Response> {
    return this.request('/order-histories.json', 'GET', null, query);
  }

  /**
   * テーブル情報取得
   */
  public tableInfo(query: tableInfo.RequestQuery): AxiosPromise<tableInfo.Response> {
    return this.request('/table-info.json', 'GET', null, query);
  }

  public getSignedWss(): AxiosPromise<iot.Response> {
    return this.request('/iot.json', 'GET', null, null);
  }
}

export default API;
