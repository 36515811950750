import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';

Vue.directive('click2top', {
  bind: ($el: HTMLElement, binding) => {
    $el.addEventListener('click', () => {
      scroll();
    });
  },
});

const scroll = () => {
  VueScrollTo.scrollTo({ force: true });
}
