import Vue from 'vue';

Vue.directive('ripple', {
    bind: ($el: HTMLElement) => {
        $el.addEventListener('mousedown', (event: MouseEvent) => {
            let target = event.target as HTMLElement;

            if (!target) {
                return;
            }
            const cover = document.createElement('span');
            const coverSize = target.offsetWidth;
            const loc = target.getBoundingClientRect();

            const x = event.pageX - loc.left - window.pageXOffset - (coverSize / 2);
            const y = event.pageY - loc.top - window.pageYOffset - (coverSize / 2);

            const pos = `top: ${y}px; left: ${x}px; height: ${coverSize}px; width: ${coverSize}px;`;

            target.appendChild(cover);
            cover.setAttribute('style', pos);
            cover.setAttribute('class', 'ripple-active');

            setTimeout(() => {
                cover.remove();
            }, 2000);
        })
    },
});
