import Vuex from 'vuex';
import Vue from 'vue';
import FrontAPI from '../common/api/api_front';
import moment from 'moment';
import { Holiday } from '../model/entity/holiday';

Vue.use(Vuex);
const api = new FrontAPI();
let holidays: string[] = [];
const commonStore = new Vuex.Store({
  state: {
    mode: '',
    holidays: holidays,
  },
  getters: {
    holidays: state => () => {
      return state.holidays;
    },
  },
  actions: {
    setMode({ commit }, mode: string) {
      commit('setMode', mode);
    },
    fetchHoliday({ commit, state }, shopId: string) {
      // commit('setHolidays', [{ at: moment().format('YYYY-MM-DD') }]);
      return api.holiday({
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD')
      }) // current month
        .then((response) => {
          if (response.data.status != 'success') {
            throw new Error(response.data.message);
          }
          commit('setHolidays', response.data.data);
        })
    }
  },
  mutations: {
    setMode(state, mode: string) {
      state.mode = mode;
    },
    setHolidays(state, holidays: Holiday[]) {
      state.holidays = holidays.map(item => moment(item.at).format('YYYY-MM-DD'));
    }
  },
});

export default commonStore;
