export class Env {
  public static get HOST_ADMIN(): string {
    return process.env.VUE_APP_HOST_ADMIN || 'https://self-order.local';
  }

  public static get ROUTER_BASE_REMOTE(): string {
    return process.env.VUE_APP_ROUTER_BASE_REMOTE || '/dist/remote-order/';
  }

  public static get ROUTER_BASE_TAKEOUT(): string {
    return process.env.VUE_APP_ROUTER_BASE_TAKEOUT || '/dist/takeout/';
  }

  public static get ROUTER_BASE_SHOP(): string {
    return process.env.VUE_APP_ROUTER_BASE_SHOP || '/dist/shop/';
  }

  public static get APP_VERSION(): string {
    return process.env.VUE_APP_VERSION || 'development';
  }
}
